<template>
  <div class="opt-in-banner relative w-full max-w-auto mt-10 py-8 md:py-12 px-4 md:px-12">
    <div class="image-section absolute left-0 bottom-0 top-0 w-full h-64 -z-10">
        <img src="/images/event_sample1.png" alt="Guitar lessons" class="absolute left">
        <img src="/images/event_sample2.png" alt="Live coaching" class="absolute right">
    </div>
    <div class="banner-description w-full text-center max-w-[500px] mx-auto pt-4 md:pt-8 sm:pt-6">
        <span>
            DON’T MISS AN EXPERIENCE
        </span>
        <h1 class="text-4.5xl mt-4">
            Get Weekly Updates on New Experiences
        </h1>
        <p class="mt-4">
            Pop in your email address below and be the first to know about upcoming experiences on Well Well Well.
        </p>
    </div>
    <ClientOnly>
        <div class="flex flex-col items-center justify-center">
        <iframe src="https://wellwellwell.blog/embed" width="480" height="150" style="max-width:100%; :transparent;" frameborder="0" scrolling="no"></iframe>
        </div>
    </ClientOnly>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.opt-in-banner {
    min-height: 440px;

    .image-section {
        min-height: 380px;
        position: relative;

        .left {
            left: 0;
            bottom: 0;
        }

        .right {
            right: 0;
            top: 0;
        }

        @media (min-width: 968px) {
            position: absolute;
            min-height: 440px;
        }
    }
}
</style>
